<template>
  <div class="p-sim-selection">
    <section>
      <div class="p-personal__otherInfo">
        <div class="p-personal__otherInfo__SNS">
          <div class="p-personal__otherInfo__SNS__Advertisement">
            <div
              class="row p-personal__otherInfo__SNS__Advertisement__price p-2"
            >
              <div class="col-lg-12">
                <h2>
                  {{ $t("COMMON.PAYMENT.PAYMENT_AMOUNT") }}
                  <span>{{ getAmount }}{{ $t("COMMON.PAYMENT.TAX") }}</span>
                </h2>
              </div>
            </div>
            <!-- 1 -->
            <div
              class="p-personal__otherInfo__SNS__Advertisement__content"
              id="Methods1"
            >
              <label class="radio-layout" style="position: relative">
                <input
                  type="radio"
                  name="group1"
                  v-model="data.metadata.paymentType"
                  :value="$CONST.PAYMENT.PAYMENT_TYPE.CREDIT"
                  @click="TypeMethod(1, 0)"
                />
                <div class="btn-radio-layout">
                  {{ $t("COMMON.PAYMENT.CREDIT.TITLE") }}
                </div>
                <font-awesome-icon icon="fa-solid fa-plus" v-if="type != 1" />
                <font-awesome-icon icon="fa-solid fa-minus" v-else />
                <div class="tooltip1">
                  <span class="tooltiptext">{{
                    $t("COMMON.PAYMENT.CREDIT.RECOMMENT")
                  }}</span>
                </div>
              </label>
              <div class="payment__form" v-if="type == 1">
                <div class="method1">
                  <div class="row">
                    <div class="col-lg-12">
                      <p>{{ $t("COMMON.PAYMENT.CREDIT.TEXT") }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row mb-4 card-hear">
                        <div class="col-sm-3">
                          <img
                            src="@/assets/assets/images/mastercard_curved_icon.png"
                            alt=""
                          />
                        </div>
                        <div class="col-sm-3">
                          <img
                            src="@/assets/assets/images/visa_curved_icon.png"
                            alt=""
                          />
                        </div>
                        <div class="col-sm-3">
                          <img
                            src="@/assets/assets/images/discover_curved_icon.png"
                            alt=""
                          />
                        </div>
                        <div class="col-sm-3">
                          <img
                            src="@/assets/assets/images/paypal_curved_icon.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div
                        v-show="cardNo != '' && settlementType == 1"
                        class="col mb-4 card"
                      >
                        <div class="card_title">
                          {{ $t("COMMON.PAYMENT.CARD_TITLE") }}
                        </div>
                        <div class="card_content">
                          {{ $t("COMMON.PAYMENT.CARD_NO") }}
                          <span>{{ data.cardNumber }}</span>
                        </div>
                      </div>
                      <div
                        v-show="
                          cardNo == '' || cardNo == null || settlementType != 1
                        "
                        class="row mb-4"
                      >
                        <div class="col-lg-12 col-sm-12">
                          <b-form-input
                            class="form-input"
                            :disabled="cardNo != ''"
                            v-model="data.cardNumber"
                            :placeholder="$t('COMMON.PAYMENT.CREDIT.PLA1')"
                            type="text"
                          >
                          </b-form-input>
                          <!-- validate -->
                          <div v-show="alert" class="validation-message">
                            <span
                              class="validation-message"
                              v-show="data.cardNumber == ''"
                              >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        v-show="
                          cardNo == '' || cardNo == null || settlementType != 1
                        "
                        class="row mb-4"
                      >
                        <div class="col-lg-6 col-sm-6 date">
                          <b-form-input
                            class="form-input"
                            v-model="data.expYear"
                            :placeholder="$t('COMMON.PAYMENT.CREDIT.PLA2')"
                            type="text"
                          ></b-form-input>
                          <!-- validate -->
                          <div v-show="alert" class="validation-message">
                            <span
                              class="validation-message"
                              v-show="data.expYear == ''"
                              >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                            >
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-xs-6">
                          <b-form-input
                            v-model="data.expMonth"
                            @change="setMonth"
                            @keypress="isNumber($event)"
                            class="form-input"
                            :placeholder="$t('COMMON.PAYMENT.CREDIT.PLA3')"
                            type="number"
                            min="1"
                            max="12"
                            trim
                          />
                          <!-- validate -->
                          <div v-show="alert" class="validation-message">
                            <span
                              class="validation-message"
                              v-show="data.expMonth == ''"
                              >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        v-show="
                          cardNo == '' || cardNo == null || settlementType != 1
                        "
                        class="row mb-4"
                      >
                        <div class="col-lg-12 col-sm-12">
                          <b-form-input
                            class="form-input"
                            v-model="data.cardholder"
                            :placeholder="$t('COMMON.PAYMENT.CREDIT.PLA5')"
                            type="text"
                          >
                          </b-form-input>
                          <!-- validate -->
                          <div v-show="alert" class="validation-message">
                            <span
                              class="validation-message"
                              v-show="data.cardholder == ''"
                              >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        v-show="
                          cardNo == '' || cardNo == null || settlementType != 1
                        "
                        class="row mb-4"
                      >
                        <div class="col-lg-12 col-sm-12">
                          <b-form-input
                            class="form-input"
                            v-model="data.cvv"
                            :placeholder="$t('COMMON.PAYMENT.CREDIT.PLA6')"
                            type="text"
                          >
                          </b-form-input>
                          <!-- validate -->
                          <div v-show="alert" class="validation-message">
                            <span
                              class="validation-message"
                              v-show="data.cvv == ''"
                              >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 2 -->
            <div
              class="p-personal__otherInfo__SNS__Advertisement__content"
              id="Methods2"
            >
              <label class="radio-layout" style="position: relative">
                <input type="radio" name="group1" @click="TypeMethod(2)" />
                <div class="btn-radio-layout" style="text-align: left">
                  {{ $t("COMMON.PAYMENT.CODE.TITLE") }}
                </div>
                <font-awesome-icon icon="fa-solid fa-plus" v-if="type != 2" />
                <font-awesome-icon icon="fa-solid fa-minus" v-else />
              </label>
              <div class="payment__form" v-if="type == 2">
                <div class="method1">
                  <div class="row">
                    <div class="col-12 payment__form--title">
                      <h2></h2>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-12 check">
                      <label class="checkbox1">
                        {{
                          $t(
                            "SIM_SERVICE.STEP7_CONSENT_SIGN.PAYMENT_FORM.FORM2.TITLE"
                          )
                        }}
                        <input
                          type="radio"
                          checked="checked"
                          v-model="data.metadata.paymentType"
                          :value="$CONST.PAYMENT.PAYMENT_TYPE.ALI"
                          name="check1"
                          @click="changeQR('ali_pay', '2')"
                        />
                        <span class="checkmark"></span>
                      </label>

                      <label class="checkbox1">
                        {{
                          $t(
                            "SIM_SERVICE.STEP7_CONSENT_SIGN.PAYMENT_FORM.FORM3.TITLE"
                          )
                        }}
                        <input
                          type="radio"
                          checked="checked"
                          v-model="data.metadata.paymentType"
                          :value="$CONST.PAYMENT.PAYMENT_TYPE.WECHAT"
                          name="check1"
                          @click="changeQR('wechat_pay', '1')"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="col-12">
                      <img :src="urlQr" />
                    </div>
                    <!-- validate -->
                    <div v-show="alert" class="validation-message">
                      <span
                        class="validation-message"
                        v-show="data.metadata.paymentType == ''"
                        >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 3 -->
            <div
              class="p-personal__otherInfo__SNS__Advertisement__content"
              id="Methods3"
            >
              <label class="radio-layout" style="position: relative">
                <input
                  type="radio"
                  name="group1"
                  v-model="data.metadata.paymentType"
                  :value="$CONST.PAYMENT.PAYMENT_TYPE.COMBINI"
                  @click="TypeMethod(3)"
                />
                <div class="btn-radio-layout">
                  {{ $t("COMMON.PAYMENT.STORE.TITLE") }}
                </div>
                <font-awesome-icon icon="fa-solid fa-plus" v-if="type != 3" />
                <font-awesome-icon icon="fa-solid fa-minus" v-else />
              </label>
              <div class="payment__form" v-if="type == 3">
                <div class="method1">
                  <div class="row">
                    <div class="col-lg-12">
                      <p>{{ $t("COMMON.PAYMENT.STORE.TEXT") }}</p>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-lg-12 col-sm-12">
                      <b-form-input
                        class="form-input"
                        v-model="data.customerName"
                        :placeholder="
                          $t(
                            'SIM_SERVICE.STEP7_CONSENT_SIGN.PAYMENT_FORM.FORM4.PLA1'
                          )
                        "
                        type="text"
                      >
                      </b-form-input>
                      <!-- validate -->
                      <div v-show="alert" class="validation-message">
                        <span
                          class="validation-message"
                          v-show="data.customerName == ''"
                          >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-lg-12 col-sm-12">
                      <b-form-input
                        class="form-input"
                        v-model="data.localNumber"
                        :placeholder="
                          $t(
                            'SIM_SERVICE.STEP7_CONSENT_SIGN.PAYMENT_FORM.FORM4.PLA2'
                          )
                        "
                        type="text"
                      >
                      </b-form-input>
                      <!-- validate -->
                      <div v-show="alert" class="validation-message">
                        <span
                          class="validation-message"
                          v-show="data.localNumber == ''"
                          >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-lg-12 col-sm-12 payment__form--select">
                      <p>
                        {{
                          $t(
                            "SIM_SERVICE.STEP7_CONSENT_SIGN.PAYMENT_FORM.FORM4.PLA3"
                          )
                        }}
                      </p>
                      <b-form-select
                        :options="option_store"
                        v-model="data.convenienceStore"
                      ></b-form-select>
                      <!-- validate -->
                      <div v-show="alert" class="validation-message">
                        <span
                          class="validation-message"
                          v-show="data.convenienceStore == ''"
                          >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- banking -->
            <div
              class="p-personal__otherInfo__SNS__Advertisement__content"
              id="Methods5"
            >
              <label class="radio-layout" style="position: relative">
                <input
                  type="radio"
                  name="group1"
                  v-model="data.metadata.paymentType"
                  :value="$CONST.PAYMENT.PAYMENT_TYPE.BANK"
                  @click="TypeMethod(5, 5)"
                />
                <div class="btn-radio-layout">
                  {{ $t("COMMON.PAYMENT.BANKING") }}
                </div>
                <font-awesome-icon icon="fa-solid fa-plus" v-if="type != 5" />
                <font-awesome-icon icon="fa-solid fa-minus" v-else />
              </label>
              <div class="payment__form" v-if="type == 5">
                <div class="method1">
                  <div class="row">
                    <div class="col-lg-12"></div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 4 -->
            <div
              class="p-personal__otherInfo__SNS__Advertisement__content"
              id="Methods4"
            >
              <label class="radio-layout" style="position: relative">
                <input
                  type="radio"
                  name="group1"
                  v-model="data.metadata.paymentType"
                  :value="$CONST.PAYMENT.PAYMENT_TYPE.STORE"
                  @click="TypeMethod(4)"
                />
                <div class="btn-radio-layout">
                  {{ $t("COMMON.PAYMENT.CASH.TITLE") }}
                </div>
                <font-awesome-icon icon="fa-solid fa-plus" v-if="type != 4" />
                <font-awesome-icon icon="fa-solid fa-minus" v-else />
              </label>
              <div class="payment__form" v-if="type == 4">
                <div class="method1">
                  <div class="row">
                    <div class="col-lg-12">
                      <p>{{ $t("COMMON.PAYMENT.CASH.TEXT1") }}</p>
                      <p>{{ $t("COMMON.PAYMENT.CASH.TEXT2") }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- info -->
            <div class="info-bonus">
              <div class="info-bonus__note">
                <p>{{ $t("COMMON.PAYMENT.CHOOSE_METHOD.TITLE") }}</p>
              </div>
              <div class="info-bonus__select">
                <div class="info-bonus__select--check">
                  <label class="checkbox">
                    {{ $t("COMMON.PAYMENT.CHOOSE_METHOD.METHOD1") }}
                    <input
                      type="radio"
                      v-model="nextPayType"
                      value="0"
                      checked="checked"
                      name="check2"
                    />
                    <span class="checkmark"></span>
                  </label>

                  <label class="checkbox">
                    {{ $t("COMMON.PAYMENT.CHOOSE_METHOD.METHOD2") }}
                    <input
                      type="radio"
                      v-model="nextPayType"
                      value="1"
                      checked="checked"
                      name="check2"
                    />
                    <span class="checkmark"></span>
                  </label>

                  <label class="checkbox">
                    {{ $t("COMMON.PAYMENT.CHOOSE_METHOD.METHOD3") }}
                    <input
                      type="radio"
                      v-model="nextPayType"
                      value="2"
                      checked="checked"
                      name="check2"
                    />
                    <span class="checkmark"></span>
                  </label>

                  <label class="checkbox">
                    {{ $t("COMMON.PAYMENT.CHOOSE_METHOD.METHOD4") }}
                    <input
                      type="radio"
                      v-model="nextPayType"
                      value="3"
                      checked="checked"
                      name="check2"
                    />
                    <span class="checkmark"></span>
                  </label>

                  <label class="checkbox">
                    {{ $t("COMMON.PAYMENT.CHOOSE_METHOD.METHOD5") }}
                    {{ $t("COMMON.PAYMENT.CODE.ALIPAY") }}
                    <input
                      type="radio"
                      v-model="nextPayType"
                      value="4"
                      checked="checked"
                      name="check2"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="info-bonus__draw">
                <div class="info-bonus__withdraw--title">
                  <p>{{ $t("COMMON.PAYMENT.WITHDRAW.TITLE") }}</p>
                </div>
                <div class="info-bonus__withdraw--content">
                  <p>{{ $t("COMMON.PAYMENT.WITHDRAW.CONTENT1") }}</p>
                  　
                  <p>
                    {{ $t("COMMON.PAYMENT.WITHDRAW.CONTENT2")
                    }}<a href="#">{{ $t("COMMON.PAYMENT.WITHDRAW.PDF") }}</a>
                  </p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6">
                <b-button
                  v-if="type == 2"
                  variant="success"
                  @click="Confirm()"
                  class="mt-3 btn btn-block"
                  type="submit"
                >
                  {{ $t("COMMON.PAYMENT.BUTTON.CONFIRM") }}
                </b-button>
                <b-button
                  v-else
                  variant="success"
                  @click="handlePayment()"
                  class="mt-3 btn btn-block"
                  type="submit"
                >
                  {{ $t("COMMON.PAYMENT.BUTTON.PAY") }}
                </b-button>
              </div>
              <div class="col-lg-6">
                <b-button
                  @click="returnFee()"
                  variant="success"
                  class="mt-3 btn btn-block btn-cancel"
                  type="submit"
                >
                  {{ $t("COMMON.PAYMENT.BUTTON.RETURN") }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import json from "../../../../config/languages/ja.js";
export default {
  name: "Payment",
  components: {},
  props: {
    amount: {
      id: 0,
      statistics: 0,
      contractType: "",
    },
  },
  data() {
    return {
      option_store: null,
      type: 0,
      urlQr: "1",
      chargesId: null,
      alert: false,
      payType: "",
      orderId: "",
      data: {
        amount: 0,
        brand: "",
        cardNumber: "",
        cardholder: "",
        convenienceStore: "",
        customerName: "",
        cvv: "",
        expMonth: "",
        expYear: "",
        initialAmount: 0,
        localNumber: "",
        metadata: {
          businessType: "2",
          id: "",
          paymentType: "",
        },
        orderType: "",
        paymentType: "",
        regularFlag: 0,
        settlementType: "",
      },
      nextPayType:
        window.localStorage.getItem("nextPayType") == "null"
          ? 0
          : window.localStorage.getItem("nextPayType"),
      cardNo: "",
    };
  },
  created() {
    this.option_store = json.SIM_SERVICE.STEP7_CONSENT_SIGN.OPTION_STORE;
    this.init();
  },
  watch: {
    "data.expMonth"() {
      if (this.data.expMonth > 12 || this.data.expMonth.length > 2) {
        this.data.expMonth = this.data.expMonth.slice(1, 3);
      }
    },
  },
  computed: {
    getAmount() {
      return this.amount.statistics;
    },
    getId() {
      return this.amount.id;
    },
    getContract() {
      return this.amount.contractType;
    },
  },
  methods: {
    init() {
      this.$api
        .post("/ifmPeriodicalPayments/getPeriodical", {
          email: window.localStorage.getItem("email"),
        })
        .then((response) => {
          if (response.result.settlementType != 0) {
            if (response.result.cardNo != null) {
              this.cardNo = response.result.cardNo;
              this.data.cardNumber = response.result.cardNo;
              this.settlementType = response.result.settlementType;
            }
          }
        });
    },
    returnFee: function () {
      this.$emit("return-fee");
    },
    TypeMethod(value) {
      var scrollDiv =
        document.getElementById("Methods" + value).offsetTop - 200;
      window.scrollTo({ top: scrollDiv, behavior: "smooth" });
      if (this.type == value) {
        this.type = 0;
      } else {
        this.type = value;
        this.resetInput();
      }
      if (value == 1) {
        this.init();
      }
      this.alert = false;
    },
    changeQR(brand, value) {
      this.brand(value);
      const Payload = {
        amount: Number(this.getAmount),
        brand: this.data.brand,
        cardNumber: this.data.cardNumber,
        cardholder: this.data.cardholder,
        convenienceStore: this.data.convenienceStore,
        customerName: this.data.customerName,
        cvv: this.data.cvv,
        expMonth: this.data.expMonth,
        expYear: this.data.expYear,
        initialAmount: Number(this.getAmount),
        localNumber: this.data.localNumber,
        metadata: {
          businessType: this.data.metadata.businessType,
          id: this.getId,
          orderType: "",
          paymentType: value,
          revenueSummary: "",
          userId: window.localStorage.getItem("userId"),
        },
        paymentType: value,
        orderType: this.data.orderType,
        regularFlag: 0,
      };
      this.$api
        .post("ifmPay/payment", Payload)
        .then((response) => {
          if (response.success) {
            this.urlQr = "data:image/png;base64," + response.result.qrImg;
            this.chargesId = response.result.chargesId;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    resetInput() {
      this.data = {
        amount: 0,
        brand: "",
        cardNumber: this.cardNo,
        cardholder: "",
        convenienceStore: "none",
        customerName: "",
        cvv: "",
        expMonth: "",
        expYear: "",
        initialAmount: 0,
        localNumber: "",
        metadata: {
          businessType: "1",
          id: "",
          paymentType: "",
        },
        orderType: "",
        paymentType: "",
        regularFlag: 0,
      };
      this.urlQr = null;
    },
    brand(value) {
      var orderType = this.getContract;
      if (value == 1) {
        this.data.brand = "we_chat_mpm";
      } else if (value == 2) {
        this.data.brand = "alipay_merchant_qr";
      } else {
        this.data.brand = "none";
      }
      if (orderType == "0") {
        this.data.orderType = "0";
      } else if (orderType == "1") {
        this.data.orderType = "1";
      } else {
        this.data.orderType = "3";
      }
    },
    validateInput() {
      if (this.type == 0) {
        this.$bvToast.toast(this.$t("COMMON.PAYMENT.VALIDATE.INPUT"), {
          title: `Errors`,
          solid: true,
          variant: `danger`,
        });
        return false;
      } else {
        if (this.type == 1) {
          if (
            this.cardNo == "" &&
            (this.data.cardNumber == "" ||
              this.data.cardholder == "" ||
              this.data.cvv == "" ||
              this.data.expMonth == "" ||
              this.data.expYear == "")
          ) {
            this.alert = true;
            return false;
          }
        } else if (this.type == 2 && this.data.metadata.paymentType == "") {
          this.alert = true;
          return false;
        } else if (this.type == 3) {
          if (
            this.data.customerName == "" ||
            this.data.localNumber == "" ||
            this.data.convenienceStore == ""
          ) {
            this.alert = true;
            return false;
          }
        }
        return true;
      }
    },
    handlePayment: function () {
      if (this.type != 4) {
        this.Payment();
      } else if (this.type == 4) {
        this.$api
          .post("ifmCost/rocurementPay", {
            id: window.localStorage.getItem("userId"),
            payType: this.data.metadata.paymentType,
          })
          .then((response) => {
            if (response.success) {
              this.$router.push({ path: "/success", query: { suc: "4" } });
            }
          });
      }
    },
    async Payment() {
      this.brand();
      const Payload = {
        amount: Number(this.getAmount),
        brand: this.data.brand,
        cardNumber: this.data.cardNumber,
        cardholder: this.data.cardholder,
        convenienceStore: this.data.convenienceStore,
        customerName: this.data.customerName,
        cvv: this.data.cvv,
        expMonth: this.data.expMonth,
        expYear: this.data.expYear,
        initialAmount: Number(this.getAmount),
        localNumber: this.data.localNumber,
        metadata: {
          businessType: this.data.metadata.businessType,
          id: this.getId,
          orderType: "",
          paymentType: this.data.metadata.paymentType,
          revenueSummary: "",
          userId: "",
        },
        paymentType: this.data.metadata.paymentType,
        orderType: this.data.orderType,
        regularFlag: "",
      };
      if (this.type == 1) {
        Payload["regularFlag"] = "1";
      } else {
        Payload["regularFlag"] = "0";
      }
      const check = this.validateInput();
      if (check) {
        this.$api
          .post("ifmPay/payment", Payload)
          .then((response) => {
            if (response.success) {
              this.updatePay();
              this.$router.push({ path: "/success", query: { suc: "4" } });
            } else {
              if (
                response.message.search(
                  this.$t("COMMON.PAYMENT.VALIDATE.TOKEN")
                ) != -1
              ) {
                this.$bvToast.toast(this.$t("VALIDATION.MESSAGE"), {
                  title: `Errors`,
                  solid: true,
                  variant: `warning`,
                });
              } else {
                this.$bvToast.toast(this.$t("COMMON.PAYMENT.VALIDATE.FAIL"), {
                  title: `Errors`,
                  solid: true,
                  variant: `danger`,
                });
              }
            }
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    updatePay() {
      this.$api
        .post("sys/user/updatePay", {
          id: window.localStorage.getItem("userId"),
          nextPayType: this.nextPayType,
        })
        .then((response) => {
          if (response.success) {
            this.$localStorage.set("nextPayType", this.nextPayType);
          }
        });
    },
    async Confirm() {
      debugger;
      const check = this.validateInput();
      // let validationUrl = "";
      // if (
      //   this.data.metadata.paymentType == "1" ||
      //   this.data.metadata.paymentType == "2"
      // ) {
      //   validationUrl = "ifmPay/payCloudValidation";
      // } else {
      //   validationUrl = "ifmPay/validation";
      // }
      if (check) {
        await this.$api
          .post("ifmPay/payCloudValidation", { chargesId: this.chargesId })
          .then((response) => {
            if (response.success) {
              this.$api
                .post("ifmCost/rocurementPay", {
                  id: this.amount.id,
                  payType: this.data.metadata.paymentType,
                })
                .then((response) => {
                  if (response.success) {
                    this.$router.push({
                      path: "/success",
                      query: { suc: "4" },
                    });
                  }
                });
              this.$router.push({ path: "/success", query: { suc: "4" } });
            } else {
              this.$bvToast.toast(this.$t("COMMON.PAYMENT.VALIDATE.MESSAGE"), {
                title: `Errors`,
                solid: true,
                variant: `warning`,
              });
            }
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    setMonth() {
      if (this.data.expMonth < 10 && this.data.expMonth != "") {
        this.data.expMonth = "0" + this.data.expMonth;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>
